

import { createElement, useEffect, useState } from "react";


const App = () => {
    const apiSource = "/api/"
    const startTime = new Date("2024-11-18 10:00:00");

    const [currentTime, setCurrentTime] = useState( new Date() );
    const [days, setDays] = useState('');
    const [deltaHours, setDeltaHours] = useState('');
    const [deltaMinutes, setDeltaMinutes] = useState('');
    const [deltaSeconds, setDeltaSeconds] = useState('');
    const [cursor, setCursor] = useState(true);

    const [taps, setTaps] = useState([]);
    const [sitoraClicks, setSitoraClicks] = useState(0);
    const [taniaClicks, setTaniaClicks] = useState(0);
    const [clicksCount, setClicksCount] = useState(1);

   

    useEffect( () => {
        setTimeout( () => {
            setCurrentTime( new Date() );
            setCursor(!cursor);
        }, 1000);
    });

    useEffect( () => {
        
        let delta = Math.floor( ( currentTime - startTime ) / 1000 );
        let days = Math.floor( delta / 86400 );
        delta -= days * 86400;
        let hours = Math.floor( delta / 3600 ) % 24;
        delta -= hours * 3600;
        let minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;
        let seconds = delta % 60;

        if ( hours >= 0 && hours <= 9 ) {
            hours = '0' + hours;
        }

        if ( minutes >= 0 && minutes <= 9 ) {
            minutes = '0' + minutes;
        }

        if ( seconds >= 0 && seconds <= 9 ) {
            seconds = '0' + seconds;
        }

        setDays(days);
        setDeltaHours(hours);
        setDeltaMinutes(minutes);
        setDeltaSeconds(seconds);
    }, [currentTime]);

    useEffect( () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({})
        };
        fetch(apiSource + "getLikes.php", requestOptions)
        .then(response => response.json())
        .then(data => {
            setSitoraClicks(parseInt(data.sitora));
            setTaniaClicks(parseInt(data.tania));      
        });
    }, []);

   


    const SaveClicks = (sitoraUpdate, taniaUpdate) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ sitora: sitoraUpdate, tania: taniaUpdate})
        };
        fetch(apiSource + "setLikes.php", requestOptions)
        .then(response => response.json())
        .then(data => {
           
        });
    }


    const Tap = (e, name) => {
        setClicksCount(clicksCount + 1);

        const mx = e.pageX;
        const my = e.pageY;

        setTaps([...taps, {mx: mx, my: my, key: String(clicksCount)}]);
        
        if ( name == 'sitora' ) {
            setSitoraClicks(sitoraClicks + 15);
            SaveClicks(sitoraClicks + 15, taniaClicks);
        }

        if ( name == 'tania' ) {
            setTaniaClicks(taniaClicks + 15);
            SaveClicks(sitoraClicks, taniaClicks + 15);
        }

        
        
    }

    const tapsMap = taps.map( item => 
        <div className="tap" style={{top: item.my, left: item.mx}} key={item.key}>+15</div>
    );


    return(
        <div className="wrapper">
            <div className="effect">
                <div className="container" id="container">
                    {tapsMap}
                    <div className="header">0% sugar</div>
                    <div className="grid">
                        <div className="photo">
                            <div className="donate">Please tap(click) Sitora's photo for donations!</div>
                            <div className="photo-content">
                                <div className="imgClick" style={{backgroundImage: `url(/sitora.jpg)`}} onMouseDown={(e) => Tap(e, 'sitora')} ></div>
                            </div>
                            <div className="name">Sitora: {sitoraClicks}$</div>
                            <div className="timer">
                                <div className="days"><div className="cl-days"><span>{days}</span> days</div></div>
                                <div className="clock">
                                    <div className="cl-hours">{deltaHours}</div>
                                    <div className="cl-step">{cursor && <>:</>}</div>
                                    <div className="cl-minutes">{deltaMinutes}</div>
                                    <div className="cl-step">{cursor && <>:</>}</div>
                                    <div className="cl-seconds">{deltaSeconds}</div>
                                </div>
                            </div>
                        </div>
                        <div className="and">&</div>
                        <div className="photo">
                            <div className="donate">Please tap(click) Tanya's photo for donations!</div>
                            <div className="photo-content">
                                <div className="imgClick" style={{backgroundImage: `url(/tania.jpg)`}} onMouseDown={(e) => Tap(e, 'tania')} ></div>
                            </div>
                            <div className="name">Tanya: {taniaClicks}$</div>
                            <div className="timer">
                                <div className="days"><div className="cl-days"><span>{days}</span> days</div></div>
                                <div className="clock">
                                    <div className="cl-hours">{deltaHours}</div>
                                    <div className="cl-step">{cursor && <>:</>}</div>
                                    <div className="cl-minutes">{deltaMinutes}</div>
                                    <div className="cl-step">{cursor && <>:</>}</div>
                                    <div className="cl-seconds">{deltaSeconds}</div>
                                </div>
                            </div>
                         
                        </div>
                    </div>
                    <div className="bottom-donate">Please donate for protein bars!</div>
                </div>
            </div>
        </div>
    )

}

export default App